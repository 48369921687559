@import 'styles/fields/datepicker.scss';
@import 'styles/fields/autosuggest.scss';
@import 'styles/pagination.scss';


*{
    margin: 0;
}

.app{
    font-size: 14px !important;
    button {
        font-size: 14px !important;
    }

    text-align: center;
}

.react-autosuggest__container {
  position: relative;
  display: flex;
  justify-content: center;
}

.react-autosuggest__input {
  width: 100%;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  border: 1px solid #aaa;
  border-radius: 4px;
  color: #495057;

}

.react-autosuggest__input--focused {
  // outline: none;
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
  max-height: 200px !important;
}


.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 30px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  color: #495057;
  font-family: Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 100px;
  overflow-y: auto;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

